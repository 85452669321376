<template>
  <v-container>
    <v-card class="px-5 py-3">
      <v-row :align="'center'" :justify="'center'">
        <v-card-text>
          <v-row>
            <v-col cols="4" sm="4" md="4">
              <v-text-field
                v-if="selected_channel.title"
                disabled
                :value="selected_channel.title"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-card-text>
      </v-row>
      <v-row>
        <v-col>
          <blocks
            :saveBlocks="saveBlocks"
            :has_cancel="true"
            :cancel="cancel"
            :disabledBtn="disabledBtn"
          ></blocks>
        </v-col>
      </v-row>
    </v-card>
    <v-overlay :value="overlay">
      <v-progress-circular
        :size="70"
        :width="7"
        color="purple"
        indeterminate
      ></v-progress-circular>
    </v-overlay>
  </v-container>
</template>

<script>
import blocks from "../../main/components/Blocks";
import toast from "../../../plugins/toast";
import { SalesApiUrls } from "@/services/accommodationRequests/sales.api.js";

export default {
  data() {
    return {
      overlay: false,
      disabledBtn: false,
      selected_channel: {}
    };
  },
  components: {
    blocks
  },
  created() {
    this.loadSale();
  },
  methods: {
    cancel() {
      this.$store.commit("OPEN_BLOCK", false);
      this.$router.push("/list");
    },
    async saveBlocks(blocks) {
      this.$store.commit("OPEN_BLOCK", false);
      if (!this.selected_channel.id) {
        toast.error("Выберите канал продаж!");
        return;
      }
      let post_continue = true;
      blocks.forEach(el => {
        el.sales_channel = this.selected_channel.id;
        if (el.category === null) {
          toast.error("Заполните все поля в блоках или удалите не нужный блок");
          post_continue = false;
        }
      });
      if (!post_continue) {
        return;
      }

      let blocks_upd = [];
      blocks.forEach(e => {
        if (e.rooms.length > 0) {
          e.rooms.forEach(j => {
            let check_in, check_out;
            if (localStorage.getItem("check_in_time"))
              check_in =
                e.check_in + " " + localStorage.getItem("check_in_time");
            else check_in = e.check_in;

            if (localStorage.getItem("check_out_time"))
              check_out =
                e.check_out + " " + localStorage.getItem("check_out_time");
            else check_out = e.check_out;
            blocks_upd.push({
              room: j,
              sales_channel: e.sales_channel,
              check_in: new Date(check_in).getTime() / 1000,
              check_out: new Date(check_out).getTime() / 1000
            });
          });
        }
      });

      this.overlay = true;
      this.disabledBtn = true;
      try {
        await SalesApiUrls.createBlock(blocks_upd);
        toast.success("Успешно добавлено!");
      } finally {
        this.disabledBtn = false;
        this.overlay = false;
      }
    },
    async loadSale() {
      let res = await SalesApiUrls.getSale(this.$route.query.id);
      this.selected_channel = res;
    }
  }
};
</script>
